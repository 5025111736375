import React from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import get from "lodash/get";

import "./footer-social-media-icon.m.css";

const FooterSocialMediaIcons = ({ footerSocialLinks = [] }) => {
  const list = [
    {
      componentName: "ri-facebook-fill",
      url: "https://www.facebook.com/Viiskunta",
      alt: "Viiskunt Facebookissa"
    },
    {
      componentName: "ri-instagram-fill",
      url: "https://www.instagram.com/viiskunta/",
      alt: "Viiskunta Instagramissa"
    }
  ];

  return (
    <ul styleName="wrapper">
      {list.map((item, index) => {
        const FooterSocialMediaIcon = item.componentName;
        return (
          item.url && (
            <li styleName="footer-share-item" key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.url || "/"}
                      aria-label="Seuraa Somessa!"
                    >
                <i className={FooterSocialMediaIcon} />
              </a>
            </li>
          )
        );
      })}
    </ul>
  );
};

function mapStateToProps(state) {
  return {
    footerSocialLinks: get(state, ["qt", "config", "social-links"], [])
  };
}

FooterSocialMediaIcons.propTypes = {
  footerSocialLinks: object
};

export default connect(mapStateToProps, () => ({}))(FooterSocialMediaIcons);
