import { useEffect, useState } from "react";

const useUrl = () => {
  const [toHideNative, setToHideNative] = useState(false);
  const [fullUrl, setFullUrl] = useState("");
  useEffect(() => {
    if (typeof window === "undefined") {
       console.log("Oops, `window` is not defined")

     } else {
      setFullUrl(window.location.href);
       const nativePageObj = window.location.href.split('/')[3];
       const nativePage = nativePageObj.includes("native-ad-card");
       if (nativePage) {
        setToHideNative(true);
       }
     }

  }, [])


  return [toHideNative, fullUrl]
}
export default useUrl;
