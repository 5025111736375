import React, { useState, useEffect } from "react";
import { Link } from "@quintype/components";

import "./breaking-news.m.css";

const renderBreakingNewsMarquee = storiesData => {
  var shownSlug;
  const items = storiesData.slice(0, 2).map(item => {
    const story = item.story;
    const accessType = story.access;
    const storySlug = story.slug.substring(story.slug.lastIndexOf('/') + 1) || story.slug;

    //Uncomment if alternative headline is wanted to be taken into use, also change all(3) story.headline:s to headline in return code.
    {/*    var headline;
    if (story.alternative && story.alternative.home && story.alternative.home.default && story.alternative.home.default["headline"]){
      headline = story.alternative.home.default["headline"];
    } else {
      headline = story.headline;
    }*/}

    if (storySlug) {
      const accessType = story.access;

      // use other than breaking news section in a slug if some other section of the paper is also set for story
      var localSection = story.sections.filter(section => section["domain-slug"] === "viiskunta" && section.slug !== "nostot");
      var breakingNewsSection = story.sections.filter(section => section["domain-slug"] === "viiskunta" && section.slug === "nostot");
      if (localSection.length > 0 && localSection !== undefined) {
        shownSlug = localSection[0].slug + "/" + storySlug;
      } else if (breakingNewsSection > 0 && breakingNewsSection !== undefined) {
        shownSlug = breakingNewsSection[0].slug + storySlug;
      } else {
        shownSlug = story.slug
      }

      return (
        <Link
          aria-label={`${story.headline}`}
          key={story.id}
          styleName="item"
          href={`/${shownSlug}`}
        >
          {(accessType === "subscription" || accessType === null || accessType === "login") && <i className="ri-star-fill"></i>}
          {`${story.headline}`}
        </Link>
      );
    }
    return (
      <div key={story.id} styleName="item">
        {(accessType === "subscription" || accessType === null || accessType === "login") && <i className="ri-star-fill"></i>}
        {story.headline}
      </div>
    );
  });
  return (
    <div>
      {items}
    </div>
  );
};

export const BreakingNewsLocalView = () => {
  const [storiesData, setStoriesData] = useState([]);

  useEffect(() => {
    async function fetchAPI() {
      const stories = await fetch(`/api/v1/collections/nostot-viiskunta`)
      const response = await stories.json();
      const resultData = response.items;
      setStoriesData(resultData);
    }
    fetchAPI();
  }, [])

  return (
    <div styleName={(storiesData.length === 0) ? "hide" : "orangee-marquee-container"}>
      <div>
        {renderBreakingNewsMarquee(storiesData)}
      </div>
    </div>
  );
};
